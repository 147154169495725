// this file is auto generated, do not edit it directly
module.exports = {
  "Auth": {
    "region": "us-west-2",
    "userPoolId": "us-west-2_otB35KoLi",
    "userPoolWebClientId": "29su6onmsehebp187h1jo80vm6",
    "mandatorySignIn": true,
    "authenticationFlowType": "USER_PASSWORD_AUTH"
  },
  "ApiConfig": {
    "host": "api.signal4d.dev"
  },
  "AppConfig": {
    "name": "Route Watch"
  }
}